import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default function ({ data }) {
  return (
    <Layout>
      <div>
      <section class="section">
        <div class="single">
        {data.allMarkdownRemark.edges.map(({ node }) => (

          <div>
            <ul>
              <a href={node.frontmatter.link}>
                <li>{node.frontmatter.title}</li>
                <li>{node.frontmatter.cat_prefix}{node.frontmatter.number}</li>
                <li>{node.frontmatter.edition}</li>
                <li>{node.frontmatter.date}</li>
              </a>
            </ul>
          </div>
        ))}
        </div>        
      </section>        
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark (sort: {order: DESC, fields: fileAbsolutePath}) {
      edges {
        node {
          frontmatter {
            link
            title
            cat_prefix
            number
            edition
            date(formatString: "YYYY")
          }
        }
      }
    }
  }
`