import React from "react"

export default function Layout({ children }) {
  return (
    <div class="wrapper">
    	<header>
	    	<section class="section">
	        <div class="single">
		    		<p>Horizontal, anti-capitalist, anarchist behaviours beginning as an agile electronic music label. We reject hierarchical structures, embrace rotatable roles and challenge traditional fixed artist-label relationships. We do not align with the profit-motive nor believe in a society that does so. We cultivate a practice of anarcho-collectivism with voluntary, iterative and decentralised creativity around common interests and create objectively good rhythmic electronic music.</p>
	    		</div>
    		</section>
    	</header>
      <div class="container">
	      {children}
      </div>
  		<footer>
			  <nav>
			    <ul>
			      <li><a href="https://store.sm-ll.com">store</a></li>
			      <li><a href="https://www.youtube.com/c/expectresistance">youtube</a></li>
			      <li><a href="https://www.patreon.com/horizontal_egoless_anarchistic_music_label">patreon</a></li>
			      <li><a href="https://tapesformates.bandcamp.com">tapesformates</a></li>
			      <li class="select">info@sm-ll.com</li>
			    </ul>
			  </nav>
			</footer>
    </div>
  	)
}